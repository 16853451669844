import React from 'react'
import 'twin.macro'

export default function HeroDetail({data,page}){
  let finalData= data?.schema_json;  
  let content= {
      heading:"Your New Ideal Style",
      sub_heading:"",
      description:'<span tw="font-bold">Lorem ipsum</span> dolor sit amet, consectetur adipiscing elit. Etiam sem neque, molestie sit amet venenatis et, dignissim ut erat. Sed aliquet velit id dui eleifend, sed consequat odio sollicitudin.',
      img_src:"",
     ...finalData
  } 
    return (
      <div tw="px-4 py-4 md:py-8 mx-auto w-full md:px-24 lg:px-8 ">
        <div tw="flex flex-col max-w-screen-xl overflow-hidden border rounded shadow-sm lg:flex-row sm:mx-auto ">
          <div tw="relative lg:w-1/2">
            <img
              src={content.img_src}
              alt="Hero Image"
              tw="object-cover w-full"
            />
            <svg
              tw="absolute top-0 right-0 hidden h-full text-white lg:inline-block"
              viewBox="0 0 20 104"
              fill="currentColor"
            >
              <polygon points="17.3036738 5.68434189e-14 20 5.68434189e-14 20 104 0.824555778 104" />
            </svg>
          </div>
          <div tw="flex flex-col justify-center p-8 bg-white lg:p-16 lg:pl-10 lg:w-1/2">
            <div tw="mb-3 text-3xl font-extrabold leading-none sm:text-4xl" dangerouslySetInnerHTML={{ __html: content.heading }} />
            <div tw="mb-5 text-gray-800" dangerouslySetInnerHTML={{ __html: content.description }} />
          </div>
        </div>
      </div>
    );
  };